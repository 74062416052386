// window.config = {
//   // shopApiUrl: 'https://seller.bozway.co',
//   shopApiUrl: "http://seller.soulshop.xyz",
//   // shopApiUrl: 'https://seller.soulshop.life',
//   resourceUrl: 'https://seller.soulshop.life/public/',

//   s3Url: 'https://shop-resource.s3.ap-southeast-1.amazonaws.com/',
//   helpLineLink: `/?entId=3c7cb00473fd5007530cea0901291421&language=en`
// }

export default {
  appName: 'The Shop',
  appMetaTitle: 'The Shop Home Page',
  appLogo: '@/assets/img/logo.png',
  appUrl: 'http://vue.soulshop.shop',
  // prod
  // apiUrl: 'https://ali.soulshop.app',
  // apiUrl: 'https://pro.jack123.shop',
  // apiUrl: 'http://shop.s5kz8j.com',
  apiUrl: window.config.shopApiUrl,
  // bai
  // apiUrl: 'http://shop.local.com',
  // bing
  // apiUrl: 'http://192.168.0.130',
  // hui
  // apiUrl: 'http://192.168.0.129',
  imageUrl: window.config.resourceUrl,

  s3Url: window.config.s3Url,

  demoMode: false,
  cacheVersion: 'bclebsjadjcioappygm44o08ie6ewq',
  appLanguage: 'en',
  allLanguages: [
    { name: 'English', code: 'en', flag: 'en', rtl: 0 },
    { name: 'Arabic', code: 'ar', flag: 'sa', rtl: 1 },
    { name: 'Bangla', code: 'bn', flag: 'bd', rtl: 0 },
    { name: 'Hindi', code: 'hi', flag: 'in', rtl: 0 },
    { name: 'Tamil', code: 'ta', flag: 'in', rtl: 0 },
    { name: 'France', code: 'fr', flag: 'fr', rtl: 0 },
    { name: '简体中文', code: 'cn', flag: 'cn', rtl: 0 }
  ],
  availableCountries: ['US', 'HK'],
  paymentMethods: [
    {
      status: '1',
      code: 'paypal',
      name: 'Paypal',
      img: `assets/img/cards/paypal.png`
    },
    {
      status: '1',
      code: 'stripe',
      name: 'Stripe',
      img: `assets/img/cards/stripe.png`
    },
    {
      status: '1',
      code: 'sslcommerz',
      name: 'SSLCommerz',
      img: `assets/img/cards/sslcommerz.png`
    },
    {
      status: '1',
      code: 'paystack',
      name: 'Paystack',
      img: `assets/img/cards/paystack.png`
    },
    {
      status: '1',
      code: 'flutterwave',
      name: 'Flutterwave',
      img: `assets/img/cards/flutterwave.png`
    },
    {
      status: null,
      code: 'razorpay',
      name: 'Razorpay',
      img: `assets/img/cards/razorpay.png`
    },
    {
      status: '1',
      code: 'paytm',
      name: 'Paytm',
      img: `assets/img/cards/paytm.png`
    },
    {
      status: '1',
      code: 'cash_on_delivery',
      name: 'Cash on Delivery',
      img: `assets/img/cards/cod.png`
    }
  ],
  offlinePaymentMethods: [],
  addons: [],
  general_settings: {
    wallet_system: '1',
    conversation_system: null,
    sticky_header: null,
    chat: { customer_chat_logo: '', customer_chat_name: 'The Shop Support' },
    social_login: { google: '1', facebook: '1', twitter: '1' },
    currency: {
      code: '',
      symbol: '$',
      decimal_separator: '1',
      symbol_format: '1',
      no_of_decimals: '2',
      truncate_price: null
    }
  },
  banners: {
    login_page: { img: '', link: '/all-categories' },
    registration_page: { img: '', link: '/all-categories' },
    forgot_page: { img: '', link: '/all-categories' },
    listing_page: { img: '', link: '/all-categories' },
    product_page: { img: '', link: '/all-categories' },
    checkout_page: { img: '', link: '/all-categories' },
    dashboard_page_top: { img: '', link: '/user/dashboard' },
    dashboard_page_bottom: { img: '', link: '/all-offers' },
    all_shops_page: { img: '', link: null },
    shop_registration_page: { img: '', link: null }
  },
  refundSettings: {
    refund_request_time_period: 2592000,
    refund_request_order_status: ['delivered'],
    refund_reason_types: [
      'Ordered the wrong product',
      'The merchant shipped the wrong product',
      'The product is damaged or defective',
      'The product arrived too late',
      'The product do not match the description'
    ]
  },
  authSettings: {
    customer_login_with: 'email',
    customer_otp_with: 'email'
  }
}
